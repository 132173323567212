import { isHtmlWidget } from '~/app/core/apiClient';
import {
  ArticlePage as ArticleApiPage,
  BasePage,
  Category,
  EventPage as EventApiPage,
  Page as ApiPage,
  PlacePage as PlaceApiPage,
  RegionPage as RegionApiPage,
  TradeOfferPage as TradeOfferApiPage,
  ItineraryPage as ItineraryApiPage,
} from '~/app/core/apiClient/api';
import { BreadcrumbInterface } from '~/components/molecules/breadcrumbItem/BreadcrumbItem';
import isSlider from '~/components/organisms/slider/isSlider';
import {
  createVoucherData,
  VoucherData,
} from '~/components/templates/common/Voucher';
import { WinterInfoData } from '~/components/templates/place/WinterData';
import { parse } from '~/utils/date-fns';
import { isLocaleSupported } from '~/utils/localization';
import {
  createWidgets,
  getImageFromWidget,
  Widget,
} from '~/utils/views/widgets';
import { Advertisement, createAdvertisements } from './advertisements';
import ImageFilterTypeEnum = BasePage.ImageFilterTypeEnum;
import { PlanDay } from '~/components/templates/common/itinerary/ItineraryPlanDay';
import getItineraryPlanDays from '../itinerary/getItineraryPlanDays';
import { PageTag, createPageTag, isPageTag } from '../pageTag/pageTag';

export enum CztPage {
  ARTICLE = 'VCR.ArticlePage',
  CATEGORY = 'VCR.CategoryPage',
  EVENT = 'VCR.EventPage',
  PAGE = 'VCR.Page',
  PLACE = 'VCR.PlacePage',
  REGION = 'VCR.RegionPage',
  TRADE_OFFFER = 'VCR.TradeOfferPage',
  ITINERARY = 'VCR.ItineraryPage',
}

interface SharedData {
  anchor?: string;
  anchorCustom?: string;
  anchorTitle?: string;
  automatedTranslationMessage: string;
  banners: Advertisement[];
  breadcrumbs: BreadcrumbInterface[];
  hideBreadcrumbs: boolean;
  canonicalPath: string;
  className: CztPage;
  content: string;
  created?: string;
  allowedCultures: string[];
  availableCultures: string[];
  documentName: string;
  guid: string;
  firstWidgetAsHero: boolean;
  imagesToPreload: string[];
  image?: string;
  imageFilter?: ImageFilterTypeEnum;
  imageSource?: string;
  modified?: string;
  navigation?: string;
  nodeAliasPath: string;
  perex: string;
  showRightColumn: boolean;
  showAutomatedTranslationVoting: boolean;
  subtitle?: string;
  title: string;
  titlePosition?: string;
  topLayerImage?: string;
  url?: string;
  widgets: {
    after: Widget[];
    before: Widget[];
    main: Widget[];
    right: Widget[];
  };
}

// No extra properties for generic page
export type Page = SharedData & {
  pageTags?: PageTag[];
};

export interface ArticlePage extends SharedData {
  date: Date | null;
  moreInfo?: string;
}

export interface PlaceAndEventPage extends SharedData {
  address: string;
  categories: Category[];
  email: string;
  latitude: number | null;
  longitude: number | null;
  phone: string;
  region: {
    guid: string;
  } | null;
  webUrl: string | null;
  web2Url: string | null;
}
export interface EventPage extends PlaceAndEventPage {
  startDate: Date | null;
  endDate: Date | null;
}

export interface PlacePage extends PlaceAndEventPage {
  vouchers: VoucherData | null;
  winter?: WinterInfoData;
  weather: boolean;
}

export interface RegionPage extends SharedData {
  region: {
    guid: string;
  } | null;
}

export interface TradeOfferPage extends SharedData {
  address?: string;
  cultureCode?: string;
  email?: string;
  facebook?: string;
  phone?: string;
  twitter?: string;
  webUrl?: string;
  web2Url?: string;
}

export interface ItineraryPage extends SharedData {
  priority?: number;
  publicTransportTime?: string;
  carTime?: string;
  carDistance?: string;
  pageTags?: PageTag[];
  itineraryFolders?: PlanDay[];
}

export type OneOfThePages =
  | Page
  | ArticlePage
  | EventPage
  | PlacePage
  | RegionPage
  | TradeOfferPage
  | ItineraryPage;

function isApiPage(data: any): data is ApiPage {
  return data && data.className === CztPage.PAGE;
}

function isArticleApiPage(data: any): data is ArticleApiPage {
  return data && data.className === CztPage.ARTICLE;
}

function isEventApiPage(data: any): data is EventApiPage {
  return data && data.className === CztPage.EVENT;
}

function isPlaceApiPage(data: any): data is PlaceApiPage {
  return data && data.className === CztPage.PLACE;
}

function isRegionApiPage(data: any): data is RegionApiPage {
  return data && data.className === CztPage.REGION;
}

function isTradeOfferApiPage(data: any): data is TradeOfferApiPage {
  return data && data.className === CztPage.TRADE_OFFFER;
}

function isItineraryApiPage(data: any): data is ItineraryApiPage {
  return data && data.className === CztPage.ITINERARY;
}

function enrichPage(sharedData: SharedData, data: ApiPage) {
  let page: Page;
  page = {
    ...sharedData,
    pageTags: data.pageTags?.map(createPageTag).filter(isPageTag),
  };

  return page;
}

function enrichArticlePage(
  sharedData: SharedData,
  data: ArticleApiPage
): ArticlePage {
  let articlePage: ArticlePage;
  articlePage = {
    ...sharedData,
    className: CztPage.ARTICLE,
    date: data.date && !data.hideDate ? parse(data.date) : null,
    moreInfo:
      data.moreInfo && data.moreInfo.trim() !== ''
        ? data.moreInfo.trim()
        : undefined,
  };
  return articlePage;
}

function enrichPlacePage(
  sharedData: SharedData,
  data: PlaceApiPage
): PlacePage {
  let placePage: PlacePage;

  const { regionGuid } = data.region ? data.region : { regionGuid: null };

  const slopes = data.holidayInfo?.slopes || [];
  const lifts = data.holidayInfo?.lifts || [];

  const slopeLength = slopes.length
    ? slopes.map((slope) => slope.length || 0).reduce((a, b) => a + b)
    : 0;

  const openSlopes = slopes.length
    ? slopes.filter((slope) => slope.isOpened)
    : [];
  const slopeLenghtOpen = openSlopes.length
    ? openSlopes.map((slope) => slope.length || 0).reduce((a, b) => a + b)
    : 0;

  const winter: WinterInfoData | undefined = data.holidayInfo
    ? {
        liftNumber: lifts?.length || 0,
        liftNumberOpen: lifts?.filter((lift) => lift.isOpened).length || 0,
        liftsOpen: lifts?.some((lift) => lift.isOpened) || false,
        open:
          (data.holidayInfo?.operationCode &&
            data.holidayInfo?.operationCode > 2) ||
          false,
        slopeLenghtOpen,
        slopeLength,
        snowHeight: data.holidayInfo.snowSlopesAvg || 0,
        snowType: data.holidayInfo.snowCode || 1,
        webcam:
          data.holidayInfo.webCams
            ?.map((cam) => {
              return {
                title: cam.caption || '',
                // TODO: Try to fix image webcams
                imgUrl: '', // cam.imageLink || '',
                url: cam.videoLink || '',
              };
            })
            .filter((item) => item.url || item.imgUrl) || [],
      }
    : undefined;

  placePage = {
    ...sharedData,
    categories:
      data.categories && data.categories.length > 0 ? data.categories : [],
    className: CztPage.PLACE,
    address: data.address || '',
    email: data.email || '',
    latitude: data.latitude || null,
    longitude: data.longitude || null,
    phone: data.phone || '',
    region: regionGuid ? { guid: regionGuid } : null,
    vouchers: (data.spaVouchers && createVoucherData(data.spaVouchers)) || null,
    weather:
      typeof data.showWeatherInfo === 'undefined' ? true : data.showWeatherInfo,
    webUrl: data.webUrl || null,
    web2Url: data.web2Url || null,
    winter,
  };

  return placePage;
}

function enrichEventPage(
  sharedData: SharedData,
  data: EventApiPage
): EventPage {
  let eventPage: EventPage;
  const start = data.startDateTime ? parse(data.startDateTime) : null;
  const end = data.endDateTime ? parse(data.endDateTime) : null;

  eventPage = {
    ...enrichPlacePage(sharedData, data),
    className: CztPage.EVENT,
    endDate: end || null,
    startDate: start || null,
  };

  return eventPage;
}

function enrichRegionPage(
  sharedData: SharedData,
  data: RegionApiPage
): RegionPage {
  let regionPage: RegionPage;
  const { regionGuid } = data.region ? data.region : { regionGuid: null };

  regionPage = {
    ...sharedData,
    className: CztPage.REGION,
    region: regionGuid ? { guid: regionGuid } : null,
  };

  return regionPage;
}

function enrichTradeOfferPage(
  sharedData: SharedData,
  data: TradeOfferApiPage
): TradeOfferPage {
  let tradeOfferPage: TradeOfferPage;

  tradeOfferPage = {
    ...sharedData,
    className: CztPage.TRADE_OFFFER,
    cultureCode: data.cultureCode,
    address: data.address,
    email: data.email,
    facebook: data.facebookUrl,
    phone: data.phone,
    twitter: data.twitterUrl,
    webUrl: data.webUrl,
    web2Url: data.web2Url,
  };

  return tradeOfferPage;
}

function enrichItineraryPageData(
  sharedData: SharedData,
  data: ItineraryApiPage
) {
  let itineraryPage: ItineraryPage;

  itineraryPage = {
    ...sharedData,
    className: CztPage.ITINERARY,
    carDistance: data.carDistance,
    carTime: data.carTime,
    itineraryFolders: data.itineraryFolders
      ? getItineraryPlanDays(data.itineraryFolders)
      : [],
    pageTags: data.pageTags?.map(createPageTag).filter(isPageTag),
    priority: data.priority,
    publicTransportTime: data.publicTransportTime,
  };

  return itineraryPage;
}

export function createPage(data: BasePage): OneOfThePages {
  const breadcrumbs: BreadcrumbInterface[] = [];

  if (data.breadcrumbs) {
    data.breadcrumbs.forEach((breadcrumb) => {
      if (!breadcrumb.title) {
        return;
      }

      breadcrumbs.push({
        title: breadcrumb.title,
        url:
          breadcrumb.alternativeUrls && breadcrumb.alternativeUrls.length > 0
            ? breadcrumb.alternativeUrls[0]
            : '',
        forcedLocale: breadcrumb.forcedCulture,
      });
    });
  }

  if (data.title) {
    breadcrumbs.push({
      title: data.title,
    });
  }

  const canonicalPath = (
    data.primaryUrl ||
    (data.alternativeUrls && data.alternativeUrls.length > 0
      ? data.alternativeUrls[0]
      : data.nodeAliasPath)
  ).toLowerCase();

  const sharedData: SharedData = {
    anchor: data.anchor && data.anchor.trim() ? data.anchor.trim() : undefined,
    // Only set custom anchor if anchor contains the word 'custom' (it should be equal to _custom)
    anchorCustom:
      data.anchor && data.anchor.includes('custom')
        ? data.anchorCustom?.trim()
        : undefined,
    anchorTitle: data.anchorTitle?.trim(),
    automatedTranslationMessage: data.automatedTranslationMessage?.trim() || '',
    banners: createAdvertisements(data.pageBanners || []),
    breadcrumbs,
    hideBreadcrumbs: data.hideBreadcrumbs || false,
    canonicalPath,
    // We don't have views for other pages than those defined below in the switch,
    // so we will treat other pages as regular Page
    className: CztPage.PAGE,
    content: data.content || '',
    allowedCultures: [],
    availableCultures: [],
    firstWidgetAsHero: data.useFirstWidgetAsHeroContent || false,
    documentName: data.documentName || '',
    guid: data.nodeGuid || 'unknown',
    image: data.image,
    imageFilter: data.imageFilterType,
    imageSource: data.imageSource,
    imagesToPreload: data.image ? [data.image] : [],
    navigation: data.nearestNavigationNodeAliasPath,
    nodeAliasPath: data.nodeAliasPath,
    perex: data.perex || '',
    showRightColumn: !!data.showRightColumn,
    showAutomatedTranslationVoting:
      data.showAutomatedTranslationVoting || false,
    subtitle: data.subtitle,
    title: data.title || '',
    titlePosition: data.titlePosition || '',
    topLayerImage: data.topLayerImage,
    url: data.url,
    widgets: {
      after: createWidgets(data.widgetsSharedAfter || []),
      before: createWidgets(data.widgetsSharedBefore || []),
      main: createWidgets(data.widgets || []),
      right: createWidgets(data.rightColWidgets || []),
    },
    created: data.documentCreatedWhen
      ? typeof data.documentCreatedWhen === 'string'
        ? data.documentCreatedWhen
        : new Date(data.documentCreatedWhen).toISOString()
      : undefined,
    modified: data.documentModifiedWhen
      ? typeof data.documentModifiedWhen === 'string'
        ? data.documentModifiedWhen
        : new Date(data.documentModifiedWhen).toISOString()
      : undefined,
  };

  if (sharedData.widgets.main.length > 0) {
    if (data.useFirstWidgetAsHeroContent) {
      let widgetNr = 0;
      if (
        isHtmlWidget(sharedData.widgets.main[0]) &&
        sharedData.widgets.main.length > 1
      ) {
        widgetNr = 1;
      }
      const img = getImageFromWidget(sharedData.widgets.main[widgetNr]);
      sharedData.imagesToPreload = img ? [img] : [];
    } else if (isEventApiPage(data) || isPlaceApiPage(data)) {
      const firstSlider = sharedData.widgets.main.find(isSlider);
      if (firstSlider && firstSlider.items.length > 0) {
        sharedData.imagesToPreload = [firstSlider.items[0].backgroundImage.src];
      }
    }
  }

  data.availableInCultures?.forEach((culture) => {
    if (culture.cultureCode && isLocaleSupported(culture.cultureCode)) {
      sharedData.availableCultures.push(culture.cultureCode);
    }
  });

  data.siteCulturesAllowed?.forEach((culture) => {
    if (culture.cultureCode && isLocaleSupported(culture.cultureCode)) {
      sharedData.allowedCultures.push(culture.cultureCode);
    }
  });

  if (isApiPage(data)) {
    return enrichPage(sharedData, data);
  } else if (isEventApiPage(data)) {
    return enrichEventPage(sharedData, data);
  } else if (isArticleApiPage(data)) {
    return enrichArticlePage(sharedData, data);
  } else if (isPlaceApiPage(data)) {
    return enrichPlacePage(sharedData, data);
  } else if (isRegionApiPage(data)) {
    return enrichRegionPage(sharedData, data);
  } else if (isTradeOfferApiPage(data)) {
    return enrichTradeOfferPage(sharedData, data);
  } else if (isItineraryApiPage(data)) {
    return enrichItineraryPageData(sharedData, data);
  }

  return sharedData;
}
