import { Component, Prop } from 'vue-property-decorator';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import { Banner, Breadcrumbs } from '~/components/organisms';
import { Button, Headline } from '~/components/atoms';
import WidgetRenderer from '~/components/templates/base/WidgetRenderer';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Link, IconText } from '~/components/molecules';
import { Align } from '~/components/atoms/headline/Headline';
import TopLayerImage from '~/components/templates/common/TopLayerImage';
import { LinkTarget } from '~/utils/molecules';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { PlacePage } from '~/utils/views';
import {
  createGalleryFromSlider,
  CztWidgets,
  Widget,
} from '~/utils/views/widgets';
import createSeoTags, { createJsonLd, TYPE } from '~/utils/views/createSeoTags';
import { getUrlFromPath } from '~/app/core/router';
import { sidebarColumns } from '~/utils/sidebarColumns';
import { VideoInterface } from '~/components/atoms/video/Video';
import Voucher from '~/components/templates/common/Voucher';
import { Style } from '~/components/atoms/button/Button';
import TranslationVoting from '~/components/templates/common/TranslationVoting';
import Advertisement from '~/components/organisms/advertisement/Advertisement';
import WinterData from '~/components/templates/place/WinterData';
import WeatherForecast from '~/components/templates/place/WeatherForecast';
import MapWidget from '~/components/templates/mapWidget/MapWidget';
import {
  isPinCategory,
  PinCategory,
} from '~/components/templates/mapWidget/MapCategoryPin';
import isSlider from '~/components/organisms/slider/isSlider';
import isGallery from '~/components/organisms/gallery/isGallery';
import { anchorPreposition, createAnchorId } from '~/utils/anchor';

interface PlaceView {
  data: PlacePage;
}

@Component
export default class Place
  extends VueComponentMixin<PlaceView, {}>(HeadManagement)
  implements PlaceView {
  @Prop({ required: true })
  public data!: PlacePage;

  protected get showSideColumn(): boolean {
    return this.data.showRightColumn || this.data.banners.length > 0;
  }

  protected get firstWidget(): Widget | null {
    const widgets = [...this.data.widgets.main];
    return this.data.firstWidgetAsHero && widgets.length > 1
      ? widgets.shift() || null
      : null;
  }

  protected get mainWidgets(): Widget[] {
    const widgets = [...this.data.widgets.main];
    if (this.data.firstWidgetAsHero && widgets.length > 1) {
      widgets.shift();
    }
    return widgets;
  }

  protected get title() {
    return this.data.title;
  }

  public headTags(): string {
    return (
      createSeoTags({
        title: this.data.title,
        description: this.data.perex,
        canonicalUrl: getUrlFromPath(this.data.canonicalPath, this.$router),
        type: TYPE.ARTICLE,
        image: this.data.image,
        imagesToPreload: this.data.imagesToPreload,
        path: this.data.canonicalPath,
        mutations: this.data.availableCultures,
      }) +
      createJsonLd({
        image: this.data.imagesToPreload,
        title: this.data.title,
        created: this.data.created,
        modified: this.data.modified,
      })
    );
  }

  public render() {
    // Destructure the array so we get a new clone, because we cannot modify the prop
    // TODO: JSON serialization Quickfix... referenced get screwed up when we touch the items inside
    const widgets: Widget[] = JSON.parse(JSON.stringify(this.mainWidgets));

    // Get the first widget from the array so we can print the slider as top content
    let galleryIndex: number | null = null;
    let firstSlider =
      widgets.length > 0
        ? widgets.find((widget, index) => {
            if (isSlider(widget)) {
              galleryIndex = index;
              return true;
            }
            return false;
          })
        : null;

    let image: string | undefined = this.data.image;
    let video: VideoInterface | undefined;
    let imageSource: string | undefined = this.data.imageSource;

    if (firstSlider && galleryIndex !== null) {
      if (isSlider(firstSlider) && firstSlider.items.length > 0) {
        firstSlider = createGalleryFromSlider(firstSlider);
      }

      if (isGallery(firstSlider) && firstSlider.items.length > 0) {
        // Remove the first item
        const firstGalleryItem = firstSlider.items.shift();
        if (firstGalleryItem) {
          // Use the first item image
          image = firstGalleryItem.image.src;
          video = firstGalleryItem.video;
          imageSource = firstGalleryItem.imageSource;
        }
      }
      widgets[galleryIndex] = firstSlider;
    }

    return (
      <v-container fluid class='pa-0' key={this.data.guid}>
        {this.renderHero(image, video, imageSource)}

        {!this.data.hideBreadcrumbs && (
          <v-container class='czt-breadcrumbs__wrapper'>
            <v-row>
              <v-col cols={12} class='py-0'>
                <Breadcrumbs breadcrumbs={this.data.breadcrumbs} />
              </v-col>
            </v-row>
          </v-container>
        )}

        {this.renderContent()}
        {this.data.widgets.before.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.before} />
        )}
        {widgets.length > 0 && <WidgetRenderer widgets={widgets} />}
        {this.data.widgets.after.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.after} />
        )}
      </v-container>
    );
  }

  protected renderContent() {
    if (this.data.content || this.data.perex) {
      return (
        <v-container id={`${anchorPreposition}content`}>
          <v-row justify='center'>
            <v-col
              cols={12}
              md={this.showSideColumn ? 7 : 12}
              lg={8}
              class='py-0'
            >
              {(() => {
                const content: JSX.Element[] = [];
                if (this.data.perex) {
                  content.push(
                    <div class='czt-perex'>
                      <HtmlRenderer content={this.data.perex} />
                    </div>
                  );
                }
                if (this.data.content) {
                  content.push(
                    <div class='czt-richtext'>
                      <HtmlRenderer content={this.data.content} />
                    </div>
                  );
                }
                if (this.data.showAutomatedTranslationVoting) {
                  content.push(
                    <TranslationVoting
                      locale={this.$i18n.locale}
                      message={this.data.automatedTranslationMessage}
                      pageId={this.data.guid}
                    />
                  );
                }
                return content;
              })()}
            </v-col>
            {this.showSideColumn && (
              <v-col {...{ attrs: sidebarColumns }} class='py-0 px-0 px-md-3'>
                {this.renderSideContent()}
              </v-col>
            )}
          </v-row>
        </v-container>
      );
    } else if (this.showSideColumn) {
      return (
        <v-container>
          <v-row>
            <v-col cols={12} class='py-0'>
              {this.renderSideContent()}
            </v-col>
          </v-row>
        </v-container>
      );
    }
  }

  protected renderSideContent() {
    return (
      <div class='czt-side-content'>
        {(this.data.address ||
          this.data.email ||
          this.data.phone ||
          this.data.weather ||
          this.data.webUrl ||
          this.data.web2Url ||
          this.data.vouchers ||
          this.data.winter ||
          (this.data.latitude && this.data.longitude)) && (
          <v-container>
            <v-row>
              <v-col col={12} class='py-0'>
                {(() => {
                  if (this.data.address) {
                    return [
                      <Headline underscore level={4} align={Align.LEFT}>
                        {this.$t('app.place.address')}
                      </Headline>,
                      <HtmlRenderer
                        content={this.data.address}
                        class='pb-0'
                        side
                      />,
                      <br />,
                    ];
                  }
                })()}
                {(() => {
                  const links: JSX.Element[] = [];
                  if (this.data.email) {
                    links.push(
                      <div>
                        <Link url={`mailto:${this.data.email}`}>
                          {this.data.email}
                        </Link>
                      </div>
                    );
                  }
                  if (this.data.phone) {
                    links.push(
                      <div>
                        <Link url={`tel:${this.data.phone}`}>
                          {this.data.phone}
                        </Link>
                      </div>
                    );
                  }
                  if (links.length > 0) {
                    links.push(<br />);
                  }
                  return links;
                })()}
                {(() => {
                  if (this.data.webUrl || this.data.web2Url) {
                    const links: JSX.Element[] = [];
                    if (this.data.webUrl) {
                      links.push(
                        <div>
                          <Link url={this.data.webUrl}>{this.data.webUrl}</Link>
                        </div>
                      );
                    }
                    if (this.data.web2Url) {
                      links.push(
                        <div>
                          <Link url={this.data.web2Url}>
                            {this.data.web2Url}
                          </Link>
                        </div>
                      );
                    }
                    if (links.length > 0) {
                      links.push(<br />);
                    }
                    return links;
                  }
                })()}
                {(() => {
                  if (this.data.latitude && this.data.longitude) {
                    return [
                      <MapWidget
                        className={CztWidgets.MAP}
                        id={this.data.guid}
                        isBottomSpacingCollapsed
                        isTopSpacingCollapsed
                        latitude={this.data.latitude}
                        longitude={this.data.longitude}
                        preselectedPoi={[]}
                        zoom={13}
                        detail
                        detailCategory={
                          this.data.categories &&
                          this.data.categories.length > 0 &&
                          isPinCategory(this.data.categories[0].categoryGuid)
                            ? this.data.categories[0].categoryGuid
                            : PinCategory.DEFAULT
                        }
                      />,
                      <div class='pb-3'>
                        <Link
                          url={`https://www.google.com/maps/search/?api=1&query=${this.data.latitude},${this.data.longitude}`}
                          target={LinkTarget.BLANK}
                        >
                          <IconText icon={['common', 'MapPin']}>
                            {this.$t('app.place.showOnExternalMaps')}
                          </IconText>
                        </Link>
                      </div>,
                    ];
                  }
                })()}
                {this.data.vouchers && <Voucher data={this.data.vouchers} />}
                {this.data.weather &&
                  this.data.latitude &&
                  this.data.longitude && (
                    <WeatherForecast
                      latitude={this.data.latitude}
                      longitude={this.data.longitude}
                    />
                  )}
                {this.data.winter && <WinterData data={this.data.winter} />}
              </v-col>
            </v-row>
          </v-container>
        )}
        {this.data.showRightColumn &&
          Array.isArray(this.data.widgets.right) &&
          this.data.widgets.right.length > 0 && (
            <WidgetRenderer widgets={this.data.widgets.right} side />
          )}
        {this.data.banners.length > 0 && (
          <v-container>
            <v-row>
              <v-col col={12} class='py-0'>
                {this.data.banners.map((banner) => (
                  <Advertisement data={banner} />
                ))}
              </v-col>
            </v-row>
          </v-container>
        )}
      </div>
    );
  }

  protected renderHero(
    image?: string,
    video?: VideoInterface,
    imageSource?: string
  ): JSX.Element | undefined {
    let events = {};
    if (!this.data.anchorTitle) {
      events = {
        on: {
          click: this.anchorClick,
        },
      };
    }
    return this.firstWidget ? (
      <WidgetRenderer widgets={[this.firstWidget]} isFirst />
    ) : (
      <Banner
        backgroundImage={{
          src: image ? image : imageHeroDefault,
          alt: this.data.title + '',
        }}
        isFirst
        imageFilter={this.data.imageFilter}
        imageSource={imageSource}
        clickable={!this.data.anchorTitle}
        showArrow={!this.data.anchorTitle}
        {...events}
        title={this.data.title + ''}
        titleLevel={1}
        titlePosition={`${this.data.titlePosition}`}
        video={video}
      >
        {(() => {
          if (this.data.subtitle) {
            return (
              <Headline level={3} light>
                <HtmlRenderer content={this.data.subtitle} />
              </Headline>
            );
          }
        })()}
        {(() => {
          if (this.data.anchorTitle) {
            return (
              <Button
                class='my-3'
                buttonStyle={Style.PRIMARY_LIGHT}
                onClick={this.anchorClick}
              >
                {this.data.anchorTitle}
              </Button>
            );
          }
        })()}
        <TopLayerImage pageData={this.data} />
      </Banner>
    );
  }

  protected anchorClick() {
    const anchorId = this.data.anchorCustom || this.data.anchor || 'content';
    const anchor = document.querySelector(
      createAnchorId(anchorId, !this.data.anchorCustom)
    );
    if (anchor && anchor instanceof HTMLElement) {
      this.$vuetify.goTo(anchor);
    } else {
      this.$vuetify.goTo(createAnchorId('content'));
    }
  }
}
